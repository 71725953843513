<template>
  <div class="modal-p position-relative p-5">
    <div role="button" class="btn-close" @click="this.$emit('close')"></div>
    <form>
      <row-component>
      <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
        <label>Nº:</label>
        <div class="input-group mb-3">
          <input-component class="large-input" v-model:model-value="formData.code"/>
        </div>
      </col-component>

<!--      <col-component class-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
        <label>Nome:</label>
        <div class="input-group mb-3">
          <input-component class="large-input" v-model:model-value="formData.name"/>
        </div>
      </col-component>-->

      <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
      <label></label>
      <div class="input-group">
        <span class="input-group-text cursorPointer btn-search" id="basic-addon1" @click="getProduct()">
          <i class="search icon"></i>
        </span>
      </div>
      </col-component>
        
      </row-component>
    </form>

    <table class="table table-striped" v-if="formDateProduct">
      <thead>
      <tr>
        <th scope="col" style="width: 10%">Foto</th>
        <th scope="col" style="width: 40%">Nome</th>
        <th scope="col" style="width: 10%">Estoque</th>
        <th scope="col" style="width: 10%">Valor UN</th>
        <th scope="col" style="width: 10%">Quantidade</th>
        <th scope="col" style="width: 20%">Adicionar</th>
      </tr>
    </thead>

      <tbody>
      <tr>
        <td data-label="Foto">
          <img class="rounded-circle" width="50" :src="this.formDateProduct.photo" alt="">
        </td>
        <td data-label="Nome">{{ this.formDateProduct.name }}</td>
        <td data-label="Estoque">{{ this.formDateProduct.initial_inventory_store }}</td>
        <td data-label="Valor UN">R$ {{ this.formDateProduct.price }}</td>
        <td data-label="Quantidade">
          <input-component v-model:model-value="formDataSend.amount" @keypress="isNumber($event)"/>
        </td>
        <td data-label="Remover">
          <button-submit icon="plus" @click="addProduct(this.formDateProduct.id)" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import RowComponent from "@/components/row/RowComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import {useToast} from "vue-toastification";

const toast = useToast()


export default {
  name: 'ProductSearch',
  components: {
    ColComponent,
    ButtonSubmit,
    InputComponent,
    RowComponent

  },

  created() {
    this.checkSerach()
  },

  props: {
    productSerach: {
      required: false,
    }
  },

  data() {
    return {
      formData: {
        code: '',
        name: ''
      },

      formDateProduct: '',
      formDataSend:{
        product_id:'',
        amount:1
      }

    }
  },

  methods: {

    checkSerach() {
      if (this.productSerach.barcode) {
        this.formData.code = this.productSerach.barcode
        this.getProductClose()
      }
    },


    getProduct() {
      this.$store.dispatch("getProduct", this.formData)
          .then((r) => {
            this.formDateProduct = r
          })
          .catch(() => {
            toast.warning(`Nenhum produto foi encontrado com referência: ${this.formData.code}`,
                {timeout: 6000})
          })
    },

    getProductClose() {
      this.$store.dispatch("getProduct", this.formData)
          .then((r) => {
            this.formDateProduct = r
          })
          .catch(() => {
            toast.warning(`Nenhum produto foi encontrado com referência: ${this.formData.code}`,
                {timeout: 6000})
            setTimeout(()=>{
              this.$emit('close')
            },1000)

          })


    },

    addProduct(id){
      this.formDataSend.product_id=id
      this.$store.dispatch("addProduct", this.formDataSend)
          .then(() => {
            this.formDateProduct = ''
            this.formData.code=''
            toast.success('Produto Adicionado com sucesso',
                {timeout: 6000})
          })
          .catch(() => {
            toast.error('Erro descohecido',
                {timeout: 6000})
          })
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },




  },


}
</script>


<style scoped>
.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 80%;
  overflow-y: auto;
  min-height: 250px;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.large-input {
  width: 100%; /* Ajuste conforme necessário */
  padding: 10px; /* Ajuste o padding conforme necessário */
}

.btn-search{
  padding: 11.5px;
}

</style>