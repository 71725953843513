<template>
  <div class="card main-card rounded-0">

    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="p-3 text-uppercase">
        <h5 class="text-dark">Pedido #{{ formData.code }} |
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==1">Aberto</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==2">Em pagamento</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==3">Cancelado</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==4">Finalizado</span>
        </h5>
        <div class="d-flex flex-column">
          <small>Hora de abertura: {{ formData.openDateHour }}</small>
          <small>Hora de fechamento: {{ formData.closeDateHour }}</small>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center m-3">
        <button-submit :icon="'print'" @click="openPrintCloseSale(formData.id)"/>
      </div>

    </div>

    <div class="spacing-border"></div>

    <div class="card m-3 rounded-0 text-uppercase">
      <div class="p-3 d-flex flex-column align-items-center align-items-md-start">
        <h5 class="text-uppercase">Consultora | Cliente </h5>
        <div class="d-flex align-items-center">
          <img class="character-label-icon-profile" :src="formData.user_photo" alt="">
          <div>
            <small v-if="formData.user_creater">{{ formData.user_creater }} </small>
            <small v-else>N/I</small>
            |
            <small v-if="formData.name_client">{{ formData.name_client }} </small>
            <small v-else>N/I</small>
          </div>

        </div>
      </div>
    </div>

    <div class="card m-3 rounded-0">
      <h5 class="p-3 text-uppercase">Produtos</h5>
      <div class="table-responsive" style="max-height: 600px; overflow-y: auto;">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
          <tr>
            <th scope="col">Nome do Produto</th>
            <th scope="col">Foto</th>
            <th scope="col">Qtde.</th>
            <th scope="col">Unitário</th>
            <th scope="col">Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in formData.itens" :key="item.id">
            <td>{{ item.name_product }}</td>
            <td>
              <img :src="item.photo_product" alt="" width="60" height="60">
            </td>
            <td>{{ item.amount }}</td>
            <td>R$ {{ item.price_un }}</td>
            <td>R$ {{ item.price_total }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>

            <th scope="col" class="tableDark" v-for="total in formData.paymentsTotal" :key="total.id">R$
              {{ total.price_total }}
            </th>

          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="card m-3 rounded-0">
      <h5 class="p-3 text-uppercase">Pagamentos</h5>
      <div class="table-responsive" style="max-height: 600px; overflow-y: auto;">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
          <tr>
            <th scope="col" colspan="3">Forma</th>
            <th scope="col" colspan="2">Preço</th>



          </tr>
          </thead>
          <tbody>
          <tr v-for="payment in formData.payments" :key="payment.id">

            <td v-if="payment.type == 1" colspan="3" >Dinheiro</td>
            <td v-if="payment.type == 2" colspan="3">PIX</td>
            <td v-if="payment.type == 3" colspan="3">Cartão de Crédito</td>
            <td v-if="payment.type == 4" colspan="3">Cartão de Débito</td>

            <td colspan="2">R$ {{ payment.price }}</td>

          </tr>


          </tbody>

          <tfoot>
          <tr>
            <th scope="col" class="tableDark">
              Valor total pedido
            </th>
            <th scope="col" class="tableDark"  v-for="total in formData.paymentsTotal" :key="total.id">
              Valor total com desconto de {{total.discount}}%
            </th>

            <th scope="col" class="tableDark">
              Valor pago
            </th>

            <th scope="col" class="tableDark">
              Troco
            </th>




          </tr>
           <tr>

             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_total }}</th>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_total_discount }}</th>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_paid_out }}</th>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_change	 }}</th>

           </tr>

          </tfoot>



        </table>
      </div>
    </div>


  </div>

</template>

<script>

import {useToast} from "vue-toastification";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import router from "@/router";

const toast = useToast();

export default {
  name: "SaleShow",

  components: {
    ButtonSubmit,
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.loadSale();
  },

  data() {
    return {
      formData: {},
      paymentsTotal:''
    }
  },

  methods: {
    loadSale() {
      this.$store.dispatch('loadHistoricSale', this.id)
          .then((r) => {
            this.formData = r
            this.formData.id = this.id
            this.verifyCashier()
          })
          .catch(() => {
            toast.error("Erro desconhecido!", {timeout: 1200})
          })
    },

    openPrintCloseSale(id) {
      let url = router.resolve({name: 'sale-print-close', params: {id: id}});
      console.log(url.href);
      window.open(url.fullPath);
    },

    verifyCashier() {
      this.$store.dispatch("getBox")
    },

  },


}
</script>

<style scoped>

.main-card {
  margin: 5px 0 0 5px;
  max-height: 90vh; /* ou qualquer valor que faça sentido para o seu layout */
  overflow: auto;
}

.spacing-border {
  border: 1px solid #000;
}

table {
  font-size: 1.02rem;
}

table thead tr > th, .tableDark {
  background-color: #000;
  color: #fff;
  font-weight: 600;
  border: none;
}

tbody tr {
  border-color: #eeeeee;
}

tbody tr td,
tbody tr th {
  background-color: #fcfcfc;
  font-weight: 300;
}

.table {
  margin-bottom: 0;
}


</style>
