<template>

  <div class="modal-w d-grid position-fixed h-100 w-100 start-0 top-0" v-if="openModalBox || openModalSangria">
    <open-box-modal v-if="openModalBox" @close="closeBoxModal"/>
    <sangria-modal v-if="openModalSangria" @close="closeSangriaModal"/>
  </div>

  <div class="shadow-lg w-100 d-flex align-items-center justify-content-between headerTitle">
    <p class="mb-0 fw-bold text-uppercase"> {{ statusBox() }}</p>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button-submit @click="openModalBox =true" :icon="'lock open'" class="float-end " v-if="pdvStatus() === 2"
                     name="Abrir Caixa"/>
      <button-submit @click="closeBox()" :icon="'lock'" class="float-end buttonHeader" v-else name="Fechar Caixa"/>
      <button-submit @click="openModalSangria =true" :icon="'money bill'" class="float-end buttonHeader"
                     v-if="pdvStatus() === 1" name="Sangria"/>
      <button-submit @click="openPrint()" :icon="'print'" class="float-end buttonHeader" v-if="pdvStatus() === 1"
                     name=""/>
    </div>
  </div>

  <div class="card-infos mb-3" v-if="pdvStatus()===1">
    <RowComponent>

      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">

        <div class="card-home">
          <div class="desc">
            <p>Dinheiro</p>
            <p>R$ {{ formData.balanceMoney }}</p>
          </div>
          <div class="icon">
            <i class="money bill alternate icon"></i>
          </div>
        </div>


      </col-component>


      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">

        <div class="card-home">
          <div class="desc">
            <p>PIX</p>
            <p>R$ {{ formData.balancePix }}</p>
          </div>
          <div class="icon">
            <i class="dollar sign icon"></i>
          </div>
        </div>


      </col-component>


      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">

        <div class="card-home">
          <div class="desc">
            <p>Cartão de Crédito</p>
            <p>R$ {{ formData.balanceCredit }}</p>
          </div>
          <div class="icon">
            <i class="credit card icon"></i>
          </div>
        </div>


      </col-component>

      <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">

        <div class="card-home">
          <div class="desc">
            <p>Cartão de Débito</p>
            <p>R$ {{ formData.balanceDebit }}</p>
          </div>
          <div class="icon">
            <i class="credit card icon"></i>
          </div>
        </div>


      </col-component>


    </RowComponent>
  </div>

</template>

<style scoped>

.card {
  border: none;
}

.card-infos {
  margin: 20px;
}

.card-infos .card-home {
  border: 0;
  width: 100%;
  height: 95px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
}

.card-infos .card-home .icon {
  width: 30%;
  color: #000000;
  font-size: 3.5rem;

}

.card-infos .card-home .desc {
  padding: 0 20px;
}

.card-infos .card-home .desc p {
  margin: 0;
  color: #ababab;
  font-size: 20px;
  text-transform: uppercase;
}

.card-infos .card-home .desc p:last-child {
  margin: 0;
  color: #000000;
  font-weight: bold;
}

@media (max-width: 1290px) {
  .card-infos .card-home .desc {
    padding: 0 0 0 10px;
  }

  .card-infos .card-home .desc p {
    font-size: 16px;
  }

  .card-infos .card-home .icon {
    font-size: 3rem;
  }
}

@media (max-width: 991px) {
  .card-infos .card-home {
    width: 100%;
  }

  .card-infos .card-home .desc p {
    margin: 0;
    font-size: 16px;
  }

  .card-infos .card-home .desc {
    padding: 20px;
  }
}

@media (max-width: 428px) {
  .card-infos .card-home .desc p {
    margin: 0;
    font-size: 20px;
  }
}


</style>

<script>
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import OpenBoxModal from "@/components/modals/OpenBoxModal.vue";
import swal from "sweetalert";
import RowComponent from "@/components/row/RowComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import SangriaModal from "@/components/modals/SangriaModal.vue";
import router from "@/router";
import {useToast} from "vue-toastification";
const toast = useToast()

export default {

  components: {
    SangriaModal,
    ColComponent,
    RowComponent,
    OpenBoxModal,
    ButtonSubmit
  },


  data() {
    return {
      openModalBox: false,
      openModalSangria: false,
      formData: {
        pdv: 2,
        balanceMoney: '',
        balancePix: '',
        balanceCredit: '',
        balanceDebit: '',
        code: '',
        sale:''
      }
    }
  },

  created() {
    this.verifyCashier()
    this.getOrder()
  },

  methods: {

    verifyCashier() {
      this.$store.dispatch("getBox")
          .then((response) => {
            this.formData = response
          }).catch(() => {
        toast.error("Erro desconhecido!", {timeout: 1200})
      })
    },

    getOrder() {
      this.$store.dispatch("getOrder")
          .then((r) => {
            this.formDataOrder = r
          }).catch(() => {
        toast.error("Erro desconhecido!", {timeout: 1200})
      })
    },

    statusBox() {
      if (this.$store.state.box.items.pdv === 1)
        return "Caixa Aberto Nº " + this.formData.code
      return "Caixa Fechado"
    },

    pdvStatus() {
      return this.$store.state.box.items.pdv
    },




    closeBoxModal(r) {
      this.formData = r
      this.openModalBox = false
    },


    closeSangriaModal(r) {
      if(r)
      this.formData = r
      this.openModalSangria = false


    },

    closeBox() {
      let statusSale =this.$store.state.order.items.sale
      if (statusSale  == 1 || statusSale == 2) {
        swal({
          title: 'Atenção!',
          text: `Não foi possível fechar o caixa, pedido em andamento!`,
          closeOnClickOutside: false,

        })
      } else {

        swal({
          title: 'Atenção!',
          text: `Deseja realmente fechar o caixa sistema ${this.me.name} ?`,
          closeOnClickOutside: false,
          buttons: {
            cancel: {
              text: 'Não',
              visible: true,
              closeModal: true,
            },
            confirm: "Sim"
          }
        }).then((result) => {
          if (result) {
            this.$store.dispatch('closeBox')
                .then((r) => {
                  this.formData = r
                  toast.success('Caixa fechado com sucesso', {timeout: 1500})
                }).catch(() => {
            })
                .finally(() => this.openPrintClose())
          }
        });
      }
    },

    openPrint() {
      let url = router.resolve({name: 'box-print', params: {id: this.formData.id}});
      window.open(url.fullPath)
    },

    openPrintClose() {
      let url = router.resolve({name: 'box-print-close', params: {id: this.formData.id}});
      console.log(url.href)
      window.open(url.fullPath)
    }


  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },




  },






}


</script>