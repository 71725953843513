import axios from "axios";
import {URL_BASE_PDV} from "@/configs/constants";

export default {

    loadOrderOrderClients(context, params) {
        // console.log('User: ', params)
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_PDV}get-sale-client`, {params})
                .then((response) => {
                    context.commit('LOAD_ORDER_CLIENT', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },


    getOrder(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_PDV}get-sale`, formData)
                .then((response) => {
                    context.commit("ORDER", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },


    openOrder(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_PDV}open-sale`)
                .then((response) => {
                    context.commit("ORDER", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },


    salesUpdateClient(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}sale-update-client/${formData.idSale}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    getProduct(context, formData) {
        context.commit("LOADER", true);
        // Cria uma instância de URLSearchParams e adiciona os parâmetros
        const params = new URLSearchParams();
        if (formData.code) params.append('code', formData.code);
        if (formData.name) params.append('name', formData.name);

        // Faz a requisição para a API
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_PDV}get-sale-product?${params.toString()}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    canceledOrder(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_PDV}sale-canceled-sale`)
                .then((response) => {
                    context.commit("ORDER", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },


    addProduct(context,formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_PDV}sale-add-product`,formData)
                .then((response) => {
                    context.commit("ORDERBOX",response.data.boxTotal)
                    context.commit("ORDERPRODUCT",response.data.product)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    destroyProduct(context,id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_PDV}sale-delete-product/${id}`)
                .then((response) => {
                    context.commit("ORDERBOX",response.data.boxTotal)
                    context.commit("ORDERPRODUCT",response.data.product)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    paymentPut(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}sale-payment`)
                .then((response) => {
                    context.commit("ORDER",response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    returnSale(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}sale-return`)
                .then((response) => {
                    context.commit("ORDER",response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    addPayment(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_PDV}sale-add-payment`, formData)
                .then((response) => {
                    context.commit("ORDERBOX",response.data.boxTotal)
                    context.commit("ORDERPAYMENT",response.data.payment)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    destroyPayment(context,id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_PDV}sale-delete-payment/${id}`)
                .then((response) => {
                    context.commit("ORDERBOX",response.data.boxTotal)
                    context.commit("ORDERPAYMENT",response.data.payment)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    discountPayment(context,formdata) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}sale-update-discount`,formdata)
                .then((response) => {
                    context.commit("ORDERBOX",response.data.boxTotal)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    addAddition(context,formdata) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}sale-update-price-addition`,formdata)
                .then((response) => {
                    context.commit("ORDERBOX",response.data.boxTotal)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    getSalePrint(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_PDV}sale-print/${id}`)
                .then((response) => {
                    context.commit("ORDER",response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },


    closeSale(context,formdata) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_PDV}sale-close`,formdata)
                .then((response) => {
                    context.commit("ORDER",response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => context.commit("LOADER", false))
        })
    },



}