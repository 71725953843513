<template>

  <div class="shadow-lg w-100 d-flex align-items-center justify-content-between headerTitle">
    <p class="mb-0 fw-bold text-uppercase">{{ this.statusOrder() }}</p>
    <div class="btn-group" role="group" aria-label="Basic example">

      <button-submit :icon="'list'" @click="openOrder" class="float-end buttonHeader " v-if="orderStore == 4"
                     name="Novo pedido"/>
      <button-submit :icon="'reply all '" @click="returnSale" v-if="orderStore ==2" class="float-end buttonHeader"
                     name="Voltar"/>
      <button-submit :icon="'close'" @click="canceledSale" v-if="orderStore == 1 || orderStore == 2"
                     class="float-end buttonHeader" name="Cancelar"/>
      <button-submit :icon="'money bill alternate outline'" @click="paymentPut" v-if="orderStore ==1"
                     class="float-end buttonHeader" name="Pagamento"/>
      <button-submit :icon="'check circle outline'" @click="endSale" v-if="orderStore ==2"
                     class="float-end buttonHeader" name="Finalizar"/>
    </div>
  </div>

  <div class="painel">
    <order-products :data-client="formData" :id-sale="formData.id" v-if="orderStore == 1"></order-products>
    <order-payment id-sale="formData.id" v-if="orderStore == 2"></order-payment>
  </div>

</template>

<script>
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import OrderProducts from "@/views/create/order-products.vue";
import swal from "sweetalert";
import {useToast} from "vue-toastification";
import OrderPayment from "@/views/create/order-payment.vue";
import router from "@/router";

const toast = useToast()

export default {
  name: "OrderSearch",
  components: {
    OrderPayment,
    OrderProducts,
    ButtonSubmit,
  },

  created() {
    this.getOrder()
  },

  data() {
    return {
      formData: {
        client_id: '',
        name_client: ''
      },

      formDataOrder: {
        sale: 3,
        code: ''
      },
    }
  },

  methods: {
    verifyCashier() {
      this.$store.dispatch("getBox")
    },

    getOrder() {
      this.$store.dispatch("getOrder")
          .then((r) => {
            this.formDataOrder = r
            this.formData = r
            this.verifyCashier();
          }).catch(() => {
        toast.error("Erro desconhecido!", {timeout: 1200})
      })
    },

    openOrder() {
      this.$store.dispatch('openOrder')
          .then((r) => {
            this.formDataOrder = r
            toast.success('Pedido aberto com sucesso', {timeout: 1500})
          }).catch(() => {
      })
    },

    statusOrder() {
      if (this.formDataOrder.sale == 1)
        return "Pedido Aberto Nº " + this.formDataOrder.code

      if (this.formDataOrder.sale == 2)
        return "Pedido em pagamento Nº" + this.formDataOrder.code

      if (this.formDataOrder.sale == 4)
        return "Nehum Pedido aberto"
    },

    canceledSale() {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente cancelar o pedido ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,
          },
          confirm: "Sim"
        }
      }).then((result) => {
        if (result) {
          this.$store.dispatch("canceledOrder")
              .then((r) => {
                this.formDateProduct = r
                toast.warning(`Pedido Cancelado com sucesso`, {timeout: 6000})
              }).catch()

        }
      });
    },

    paymentPut() {
      this.$store.dispatch("paymentPut")
          .then((r) => {
            this.formDataOrder.sale = r.sale
          }).catch()
    },

    returnSale() {
      this.$store.dispatch("returnSale")
          .then((r) => {
            this.formDataOrder.sale = r.sale
          }).catch()
    },

    endSale() {
      this.$store.dispatch("closeSale")
          .then(() => {
            this.openPrintClose()
            toast.warning(`Pedido finalizado com sucesso`, {timeout: 6000})
          }).catch((e) => {
            let msg = e.response.data.error
        swal({
          title: 'Atenção!',
          text: `${msg}, ${this.me.name}!`,
          closeOnClickOutside: false,
        })

      })
    },

    openPrintClose() {
      let url = router.resolve({name: 'sale-print-close', params: {id: this.formData.id}});
      window.open(url.fullPath)
    }

  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },

    orderStore() {
      return this.$store.state.order.items.sale
    },

  }


}
</script>

<style scoped>
.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 60%;
  overflow-y: auto;
  min-height: 550px;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.painel {
  padding: 1rem;
  background: #FFFFFF;
}
</style>