export default {

    LOAD_HISTORIC(state, historic) {
        state.historic = historic
    },


    LOAD_HISTORIC_SALE(state, historic) {
        state.historicSale = historic
    },


}