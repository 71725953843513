<template>
  <nav class="bg-body-secondary position-absolute bottom-0 end-0 p-4">
    <div class="mb-3">
      <img width="40" class="rounded-circle" v-if="me.photo" :src="me.photo" alt="">
      <img width="40" class="rounded-circle" src="@/assets/avatar.png" alt="" v-else>
      <p class="mb-0 d-inline-block ms-2">{{ me.fullName }}</p>
    </div>
    <hr>

    <row-component v-if="orderStore ==1 || orderStore ==2">

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Total Venda:</label>
        <input-component disabled v-model:model-value="orderBox.price_total" class="fontBold"/>
      </col-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Desconto %:</label>
        <div class="input-group">

          <input-component :disabled="orderStore==1" maxlength="2" @keypress="isNumber($event)"
                           v-model:model-value="formDataDiscount.discount" class="fontBold"/>


          <span class="input-group-text cursorPointer" id="basic-addon1" v-if="orderStore ==2" @click="discountPayment">
                            <i class="sync alternate icon"></i>
                        </span>
        </div>


      </col-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Acréscimo R$:</label>
        <div class="input-group">

          <input-component :disabled="orderStore==1"
                           v-model:model-value="formDataDiscount.price_addition" class="fontBold" v-money="MONEY"/>
          <span class="input-group-text cursorPointer" id="basic-addon1" v-if="orderStore ==2"  @click="addAddition">
            <i class="sync alternate icon"></i>
          </span>
        </div>
      </col-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Total Venda Desconto:</label>
        <input-component disabled v-model:model-value="orderBox.price_total_discount" class="fontBold"/>
      </col-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Valor Pago:</label>
        <input-component disabled v-model:model-value="orderBox.price_paid_out" class="fontBold"/>
      </col-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Total a Pagar:</label>
        <input-component disabled v-model:model-value="orderBox.price_total_payable" class="fontBold"/>
      </col-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Troco:</label>
        <input-component disabled v-model:model-value="orderBox.price_change" class="fontBold"/>
      </col-component>

      <hr>
    </row-component>


    <row-component>

      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Horário de Abertura Caixa:</label>
        <div class="input-group">
                        <span class="input-group-text rounded-0" id="basic-addon1">
                            <i class="clock outline icon"></i>
                        </span>
          <input-component v-model="openDateHour" disabled/>
        </div>
      </col-component>


    </row-component>

  </nav>

  <div class="body">
    <main>
      <header>
        <div class="logo">
          <img src="@/assets/logo-white.png" alt="">
        </div>

        <ul>
          <li>
            <router-link :to="{name: 'box-show'}" class="button">
              <i class="m-0 archive icon"></i>
              <span>Caixa </span>
            </router-link>
          </li>


          <li v-if="menuShow">
            <router-link :to="{name: 'order-show'}" class="button">
              <i class="m-0 shopping basket icon"></i>
              <span>Pedido</span>
            </router-link>
          </li>

          <li v-if="menuShow">
            <router-link :to="{name: 'client-store'}" class="button">
              <i class="m-0 address book icon"></i>
              <span>Cliente </span>
            </router-link>
          </li>

          <li>
            <router-link :to="{name: 'historic-show'}" class="button">
              <i class="m-0 history icon"></i>
              <span>Histórico </span>
            </router-link>
          </li>


          <li>
            <div role="button" class="button" @click.prevent="logout">
              <i class="m-0 sign-out icon"></i>
              <span>Sair</span>
            </div>
          </li>

        </ul>
      </header>


      <section class="produtos">
        <router-view/>
      </section>
    </main>
  </div>


</template>

<script>


import {useToast} from "vue-toastification";


const toast = useToast()
import RowComponent from "@/components/row/RowComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import swal from "sweetalert";
import {VMoney} from "v-money"
import {MONEY} from "@/configs/constants";


export default {
  name: "AdminComponent",
  components: {
    RowComponent,
    ColComponent,
    InputComponent
  },


  directives: {
    money: VMoney
  },


  data() {
    return {
      formData: {
        pdv: "",
        balanceMoney: "",
        balancePix: "",
        balanceCredit: "",
        balanceDebit: "",
        openDateHour: "",

      },

      formDataDiscount:{
        discount: "",
        price_addition:""
      },

      sangria: false,
      openCashier: false,
      menuShow: false,
      MONEY,


    }
  },


  methods: {

    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },


    logout() {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente sair do sistema ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,
          },
          confirm: "Sim"
        }
      }).then((result) => {
        if (result) {
          this.$store.dispatch('logout')
          toast.success("Logout efetuado com sucesso!", {timeout: 2000})
          setTimeout(() => this.$router.push({name: 'login'}), 2000)
        }
      });
    },


    showMenus() {
      let statusBox = this.$store.state.box.items.pdv
      if (statusBox == 1)
        this.menuShow = true

      if (statusBox == 2)
        this.menuShow = false

    },

    discountPayment() {
      this.$store.dispatch('discountPayment',this.formDataDiscount)
          .then(()=>{
            toast.success("Desconto aplicado com sucesso!", {timeout: 2000})
          })
    },

    addAddition() {
      this.$store.dispatch('addAddition',this.formDataDiscount)
          .then(()=>{
            toast.success("Acréscimo aplicado com sucesso!", {timeout: 2000})
          })
    },


  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },

    pdv() {
      return this.$store.state.box.items.pdv
    },

    orderStore() {
      return this.$store.state.order.items.sale
    },

    orderBox() {
      return this.$store.state.order.items.boxTotal
    },

    openDateHour() {
      return this.$store.state.box.items.openDateHour
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },
  },

  watch: {
    pdv(item) {

      if (item === 1)
        this.menuShow = true
      if (item === 2)
        this.menuShow = false
    },

    orderBox(item){
      if(item) {
        this.formDataDiscount.discount = item.discount
        this.formDataDiscount.price_addition = item.price_addition
      }
    }

  },


}
</script>


<style scoped>
.body {
  width: 100vw;
  height: 100vh;
  font-family: "Segoe UI", sans-serif;
  background-color: #F2F2F2;
}

header {
  height: 80px;
  display: flex;
  position: relative;
  background-color: #000000;
}

header .logo img {
  height: 80px;
  display: block;
  margin-left: 10px;
  padding: 10px 0;
}

header ul {
  width: 60%;
  display: flex;
  list-style: none;
  margin: 0 2rem 0;
}

header ul li,
header ul li .button {
  height: 80px;
  margin: 0 1rem;
  width: fit-content;
}

header ul li .button {
  display: flex;
  color: #FFFFFF;
  align-items: center;
  text-decoration: none;
  justify-content: space-around;
}

header ul li .button i {
  line-height: 25px;
  font-size: 1.5rem;
}

header ul li .button span {
  margin-left: 10px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

main {
  float: left;
  height: 100%;
  width: calc(100% - 300px);
}

nav {
  width: 300px;
  height: 100%;
}

.fontBold {
  font-size: 20px;
  font-weight: bold;
}



@media (max-width: 1280px) {
  header ul {
    width: 60%;
    display: flex;
    list-style: none;
    margin: 0 0.5em 0;
  }

  ul {
    padding-left: rem;
}

header ul li, header ul li .button {
    margin: 0 0.5rem;
}
}
</style>