<template>

  <div class="shadow-lg w-100 d-flex align-items-center justify-content-between headerTitle">
    <p class="mb-0 fw-bold text-uppercase" v-if="typeHistoric == 1"> Histórico Caixa</p>
    <p class="mb-0 fw-bold text-uppercase" v-if="typeHistoric == 2"> Histórico Pedido</p>
    <button-submit :icon="'list'" @click="typeHistoric=2" class="float-end buttonHeader " v-if="typeHistoric == 1"
                   name="Pedido"/>
    <button-submit :icon="'list'" @click="typeHistoric=1" class="float-end buttonHeader " v-if="typeHistoric == 2"
                   name="Caixa"/>


  </div>
  <div class="painel">
    <div class="bg-body-secondary p-4 scrolAuto" v-if="typeHistoric==1">
      <div class="bg-white">
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col" style="width: 10%">Nº</th>
            <th scope="col" style="width: 15%">Cliente <br>Consultora</th>
            <th scope="col" style="width: 20%">Data <br> Hora (Aberto)</th>
            <th scope="col" style="width: 20%">Data <br> Hora (Fechado)</th>
            <th scope="col" style="width: 15%">Status</th>
            <th scope="col" style="width: 12%">Valor Venda <br> Valor Caixa</th>
            <th scope="col" style="width: 25%">Ações</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(data, index) in historicBox" :key="index">
            <td>{{ data.code }}</td>
            <td v-if="data.name_client">{{data.name_client}} <br><img :src="data.user_photo" class="user-image">{{data.user_creater}}</td>
            <td v-else>N/I <br> <img :src="data.user_photo" class="user-image">{{data.user_creater}}</td>
            <td>
              <div>{{ data.dateHourOpen.split(' - ')[0] }}</div>
              <div>{{ data.dateHourOpen.split(' - ')[1] }}</div>
            </td>
            <td v-if="data.status==1"></td>
            <td v-else>
              <div>{{ data.dateHourClose.split(' - ')[0] }}</div>
              <div>{{ data.dateHourClose.split(' - ')[1] }}</div>
            </td>
            <td v-if="data.status==1">Aberto</td>
            <td v-if="data.status==2">Fechado</td>
            <td>R$ {{ data.totalSale }} <br> R$ {{ data.boxTotal }} </td>
            <td data-label="Ações">
              <button-show :id="data.id" route="historic-show-boxes"/>
              <button-submit  :icon="'print'" @click="openPrintClose(data.id)"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="bg-body-secondary p-4 scrolAuto" v-if="typeHistoric==2">

      <row-component>
        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Nº:</label>
          <input-component v-model="searchSales.code"/>
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Cliente:</label>


          <Multiselect v-model="searchSales.client_id" label="name" :options="selectClient"
                       :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
            </template>
          </Multiselect>
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Consultora:</label>

          <Multiselect v-model="searchSales.creator_user_id" label="name" :options="selectUser"
                       :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
            </template>
          </Multiselect>
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <Multiselect v-model="searchSales.status" :options="selectStatusHistoric" placeholder=""/>
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4">
          <button-submit class="me-1" @click="submitSales" icon="search" name="Pesquisar"/>
        </col-component>
      </row-component>

      <div class="bg-white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" style="width: 10%">Nº</th>
              <th scope="col" style="width: 15%">Cliente <br>Consultora</th>
              <th scope="col" style="width: 15%">Data <br> Hora</th>
              <th scope="col" style="width: 15%">Valor</th>
              <th scope="col" style="width: 25%">Status</th>
              <th scope="col" style="width: 15%">Ações</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(data, index) in objectReturn.data" :key="index">
              <td>{{data.code}}</td>
              <td v-if="data.name_client">
                {{data.name_client}} 
                  <br>
                <img :src="data.user_photo" class="user-image">
                  {{data.user_creater}}
              </td>
              <td v-else>N/I <br> <img :src="data.user_photo" class="user-image">{{data.user_creater}}</td>

              <td>{{ data.dateOpen}} <br> {{ data.hourOpen}}</td>
      
              <td v-if="data.discount==0">R$ {{data.price_total}} </td>
              
              <td v-else-if="data.discount > 0">
                R$ {{ data.price_total }} <br>R$ {{ data.priceTotalDiscount }}
                <span class="badge">{{ data.discount }}%</span>
              </td>

              <td v-if="data.status==1">Aberto</td>
              <td v-if="data.status==2">Em pagamento</td>
              <td v-if="data.status==3">Cancelado</td>
              <td v-if="data.status==4">Finalizado</td>

              <td data-label="Ações">
                <button-show :id="data.id" route="historic-show-sale"/>
                <button-submit :icon="'print'" @click="openPrintCloseSale(data.id)"/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button-pagination :pagination="objectReturn" :offset="3" @paginate="loadItems"/>

  </div>

</template>

<style scoped>
.painel {
  padding: 1rem;
  background: #FFFFFF;
}

.badge {
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  margin-left: 25px;
}

</style>
<script>

import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import router from "@/router";
import RowComponent from "@/components/row/RowComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";
import ButtonShow from "@/components/buttons/ButtonShow.vue";
const toast = useToast()

export default {
  name: "historic-show",

  components: {
    ButtonShow,
    ButtonPagination,
    InputComponent,
    ColComponent,
    RowComponent,
    ButtonSubmit,
    Multiselect

  },

  created() {
    this.$store.dispatch('loadClients')
    this.$store.dispatch('loadUsers')
    this.loadItems(1)
    this.loadHistoricBox(1)
  },

  data() {

    return {
      typeHistoric: 2,
      historicBox: [],

      searchSales: {
        code: '',
        creator_user_id: '',
        client_id: '',
        status: ''
      },

      dataSales:{
        status:''
      },

      showLoader: true,
      searchSubmit: false,

    }

  },

  methods: {
    loadItems(page) {
      this.showLoader = true
      if (!this.searchSubmit) {
        this.$store.dispatch('loadHistoricSales', {...this.params, page})
            .then((r) => {
              this.verifyCashier()
              if (this.searchSubmit) {
                this.searchSubmit = false;
                if (r.itens.data.length === 0) {
                  toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                }
              }
            }).catch(() => {
          this.showLoader = false
          this.searchSubmit = false;
        });
      }
    },

    verifyCashier() {
      this.$store.dispatch("getBox")
    },

    openPrint(id) {
      let url = router.resolve({name: 'box-print', params: {id: id}});
      window.open(url.fullPath)
    },

    openPrintClose(id) {
      let url = router.resolve({name: 'box-print-close', params: {id: id}});
      window.open(url.fullPath)
    },

    openPrintCloseSale(id) {
      let url = router.resolve({name: 'sale-print-close', params: {id: id}});
      window.open(url.fullPath)
    },

    // openPrintCloseBoxes(id) {
    //   let url = router.resolve({name: 'sale-print-close', params: {id: id}});
    //   console.log(url.href)
    //   window.open(url.fullPath)
    // },

    submitSales() {
      this.loadItems(1)
      this.searchSubmit = true;
    },

    loadHistoricBox(page) {
        this.$store.dispatch('loadHistoricBox', {...this.params, page})
            .then((r) => {
              this.historicBox = r.data;  // Armazena os dados no estado reativo
              this.verifyCashier()
            }).catch(() => {
          this.showLoader = false
        });
    },
  },

  mounted() {
    this.loadHistoricBox(1);  // Carrega a primeira página de dados ao montar o componente
  },

  computed: {

    selectStatusHistoric() {
      return this.$store.state.var.selectStatusHistoric
    },

    selectClient() {
      return this.$store.state.client.selectClients
    },

    selectUser() {
      return this.$store.state.user.selectUsers
    },

    noResultsText() {
      return this.$store.state.var.selectNotSearch;
    },

    objectReturn() {
      return this.$store.state.historic.historicSale;
    },

    params() {
      return {
        page: this.objectReturn.current_page,
        code: this.searchSales.code,
        creator_user_id: this.searchSales.creator_user_id,
        client_id: this.searchSales.client_id,
        status: this.searchSales.status,
      }
    },
  }
}


</script>